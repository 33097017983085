import { Alert, Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField, Typography, useTheme } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { QRCodeSVG } from "qrcode.react";
import StyledFlatButton from "../../components/buttons/StyledFlatButton";
import ContentCard from "../../components/content/ContentCard";
import InvoiceNumberTitle from "../../components/InvoiceNumberTitle";
import StyledPage from "../../components/StyledPage";
import ThemeWrapper from "../../components/ThemeWrapper";
import { PhonePeIcon } from "../../icons/PhonePeIcon";
import PhonePeInvoiceLoader, { PhonePeInvoiceContext } from "./PhonepeInvoiceLoader";
import PageProgress from "../../components/PageProgress";
import axios from "axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ConctactUsButton } from "../../components/ContactUsButton";
import SelectLanguage from "../../components/SelectLanguage";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import copyToClipboard from "copy-to-clipboard";

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false; // removes the `xs` breakpoint
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        desktop: true;
    }
}

const PhonepeTitle = () => {
    return <Box sx={{
        paddingLeft: '24px',
        paddingRight: '24px',
    }}>
        <PhonePeIcon size={56} />
    </Box>
}

const Container = ({
    children,
}: {
    children: JSX.Element | JSX.Element[] | string | null,
}) => {
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
        <ContentCard>
            <PhonepeTitle />
            <Box sx={{ height: '16px' }} />
            <Box sx={{
                width: '100%',
                height: '8px',
                backgroundColor: grey[100],
            }} />
            <Box sx={{ height: '16px' }} />
            {children}
        </ContentCard>
    </Box>;
}

const SectionHeader = ({ children }: { children: JSX.Element | JSX.Element[] | string }) => {
    return <Typography variant="h6" sx={{ textAlign: 'start', textTransform: 'capitalize', fontWeight: '700' }}>{children}</Typography>
};

const SectionDescription = ({ children }: { children: JSX.Element | JSX.Element[] | string }) => {
    return <Typography variant="body1" sx={{ textAlign: 'start' }}>{children}</Typography>
};

const Price = () => {
    const theme = useTheme();
    const invoice = useContext(PhonePeInvoiceContext).invoice;
    const [open, setOpen] = useState(false);

    const handleCopy = () => {
        copyToClipboard(invoice.priceINR);
        setOpen(true);
    };

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }}>
            <Typography variant="h3">
                <FormattedNumber value={parseFloat(invoice.priceINR)} style="currency" currency='INR' />
            </Typography>
            <Box sx={{ width: '4px' }} />
            <IconButton onClick={handleCopy}><ContentCopyRoundedIcon fontSize='large' sx={{
                color: theme.palette.primary.main,
            }} /></IconButton>
        </Box>
        <Box sx={{ height: '4px' }} />
        <Typography variant="h6" sx={{ opacity: '0.5' }}>
            ≈ <FormattedNumber value={parseFloat(invoice.priceUSD)} style="currency" currency='USD' />
        </Typography>
        <Snackbar
            open={open}
            autoHideDuration={1000}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                <FormattedMessage id="msg.copied" />
            </Alert>
        </Snackbar>
    </Box>);
};

const PhonepePage = ({ onSuccess }: { onSuccess: () => void }) => {
    const invoice = useContext(PhonePeInvoiceContext).invoice;
    const [orderId, setOrderID] = useState<string | null>(null);
    const [orderAmount, setOrderAmount] = useState<string | null>(null);
    const [progress, setProgress] = useState(false);

    const checkPaymentButtonsDisabled = orderId == null
        || orderId.length == 0
        || orderAmount == null
        || orderAmount.length == 0;

    const openInAppButtonDisabled = !checkPaymentButtonsDisabled;
    const handleOpenInApp = () => {
        window.open(invoice.url, '_blank', 'noopener,noreferrer');
    };
    const handleOrderIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrderID(event.target.value);
    };
    const handleOrderAmountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrderAmount(event.target.value);
    };

    let active = true;

    useEffect(() => {
        return () => {
            active = false;
        }
    }, []);

    const handleCheckPayment = () => {
        const fetch = async () => {
            try {
                if (!active) return;
                setProgress(true);
                for (let i = 0; i < 6; ++i) {
                    try {
                        const response = await axios.post(
                            `https://api.neutrino.plus/v7/rebirth/purchases/email/confirm?token=${invoice.token}&txId=${orderId}&amount=${orderAmount}`,
                        );
                        if (!active) break;
                        if (response.status === 200) {
                            const data: {
                                status: string,
                            } = response.data;

                            if (data.status === 'ok') {
                                onSuccess();
                            }
                            break;
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    await (new Promise((resolve) => setTimeout(resolve, 5_000)));
                }
            } finally {
                if (active) {
                    setProgress(false);
                }
            }
        }
        fetch();
    };
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <InvoiceNumberTitle num={invoice.id} />
            <Box sx={{ height: '8px' }} />
            <Container>
                <SelectLanguage />
                <Box sx={{ height: '16px' }} />
                <Box sx={{
                    width: '100%', height: '8px',
                    backgroundColor: grey[100],
                }} />
                <Box sx={{ height: '16px' }} />
                <Box sx={{
                    display: 'flex',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    flexDirection: 'column',
                }}>

                    <Box sx={{ height: '16px' }} />
                    <Price />
                    <Box sx={{ height: '36px' }} />
                    <SectionHeader>
                        <FormattedMessage id="phonepe.step1.title" />
                    </SectionHeader>
                    <Box sx={{ height: '16px' }} />
                    <SectionDescription>
                        <FormattedMessage id="phonepe.step1.description" />
                    </SectionDescription>
                    <Box sx={{ height: '24px' }} />
                    <Box>
                        <QRCodeSVG value={invoice.url}
                            width='76%'
                            height='auto' />,
                    </Box>
                    <Box sx={{ height: '16px' }} />
                    <Typography variant="body1">
                        <FormattedMessage id="phonepe.qr_code.description" />
                    </Typography>
                    <Box sx={{ height: '24px' }} />
                    <StyledFlatButton handleClick={handleOpenInApp} margins={false} disabled={openInAppButtonDisabled}>
                        <FormattedMessage id="btn.open_in_app" />
                    </StyledFlatButton>
                    <Box sx={{ height: '36px' }} />
                    <SectionHeader>
                        <FormattedMessage id="phonepe.step2.title" />
                    </SectionHeader>
                    <Box sx={{ height: '16px' }} />
                    <SectionDescription>
                        <FormattedMessage id="phonepe.step2.description" />
                    </SectionDescription>
                    <Box sx={{ height: '24px' }} />
                    <TextField id="phonepe-check-order_id" label={<FormattedMessage id="transaction.id" />} variant="outlined" onChange={handleOrderIdChanged} disabled={progress} />
                    <Box sx={{ height: '24px' }} />
                    <FormControl fullWidth disabled={progress}>
                        <InputLabel htmlFor="outlined-adornment-amount">
                            <FormattedMessage id="transaction.amount" />
                        </InputLabel>
                        <OutlinedInput
                            id="phonepe-check-amount"
                            value={null}
                            onChange={handleOrderAmountChanged}
                            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                            label={<FormattedMessage id="transaction.amount" />}
                        />
                    </FormControl>
                    <Box sx={{ height: '24px' }} />
                    <StyledFlatButton handleClick={handleCheckPayment} disabled={checkPaymentButtonsDisabled} margins={false} loading={progress}>
                        <FormattedMessage id="btn.check_payment" />
                    </StyledFlatButton>
                </Box>
            </Container>
        </Box>);
};

const SuccessPage = () => {
    return <Container>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <CheckCircleIcon sx={{
                    color: green[400],
                    fontSize: '32px',
                }} />
                <Box sx={{ width: '8px' }} />
                <FormattedMessage id="common.success.title" />
            </Box>
        </Typography>
        <Box sx={{ height: '16px' }} />
        <Typography variant="body1" sx={{
            marginLeft: '24px',
            marginRight: '24px',
        }}>
            <FormattedMessage id="common.success.message" />
        </Typography>
        <Box sx={{ height: '24px' }} />
        <ConctactUsButton />
    </Container>;
};

const StateResolver = () => {
    const [success, setSuccess] = useState(sessionStorage.getItem('__.phone_pe.state.__') === 'success');

    const updateSuccess = (flag: boolean) => {
        if (flag) {
            sessionStorage.setItem('__.phone_pe.state.__', 'success');
            setSuccess(true);
        } else {
            sessionStorage.removeItem('__.phone_pe.state.__');
            setSuccess(false);
        }
    }

    return success ? <SuccessPage /> : <PhonepePage onSuccess={() => updateSuccess(true)} />;
};

const External = ({ invoiceId }: { invoiceId: string }) => {
    return <ThemeWrapper palette={{
        primary: '#6739b7',
        primaryDark: '#5f259f',
    }}>
        <StyledPage>
            <PhonePeInvoiceLoader invoiceToken={invoiceId} progressComponent={<PageProgress />}>
                <StateResolver />
            </PhonePeInvoiceLoader>
        </StyledPage>
    </ThemeWrapper>;
};

export default External;