import './FixRtl.css';
import { useContext } from "react";
import React from 'react';
import { LocalizationManagerContext } from "../lang/Localization";


const FixRtl = ({ children }: { children: JSX.Element }) => {
    const localizationManager = useContext(LocalizationManagerContext).manager;
    if (localizationManager.appLang.rtl) {
        return (<div className='FlipHorizontal'> {children}</div >);
    }
    return children;
};

export default FixRtl;