import { Card, CardHeader, Collapse, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormattedMessage } from "react-intl";
import { grey } from "@mui/material/colors";
import React from 'react';

const FrequentlyQuestion = ({
    question,
    questionId,
    answer,
    answerId,
}: {
    question?: string | JSX.Element | JSX.Element[] | null,
    questionId?: string | null,
    answer?: string | JSX.Element | JSX.Element[] | null,
    answerId?: string | null,
}) => {
    const [shadow, setShadow] = useState(false)
    const [open, setOpen] = useState(false);
    function handleOnMouseOver() {
        setShadow(true);
    }
    function handleOnMouseOut() {
        setShadow(false);
    }
    return <Card onClick={() => {
        setOpen(!open);
        setShadow(!open);
    }}
        elevation={0.0}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        sx={{
            marginLeft: '24px',
            marginRight: '24px',
            backgroundColor: grey[100],
            boxShadow: shadow ? 4.0 : 0.0,
        }}>
        <CardHeader
            sx={{
                textAlign: 'start',
            }}
            title={
                <Typography variant="body1" sx={{
                    fontWeight: '700',
                }}>
                    {questionId != null ? <FormattedMessage id={questionId} /> : question}
                </Typography>
            }
            action={
                <IconButton
                    onClick={() => setOpen(!open)}
                    aria-label="expand"
                    size="small" >
                    {
                        open
                            ? <KeyboardArrowUpIcon />
                            : <KeyboardArrowDownIcon />
                    }
                </IconButton>
            }
        >
        </CardHeader>
        <div style={{}}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{
                    textAlign: 'start',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingBottom: '16px',
                }}>
                    <Typography >
                        {answerId != null ? <FormattedMessage id={answerId} /> : answer}
                    </Typography>
                </div>
            </Collapse>
        </div>
    </Card >;
}

export default FrequentlyQuestion;