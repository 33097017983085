import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import StyledFlatButton from "../../components/buttons/StyledFlatButton";
import BinanceCheck from "./BinanceCheck";
import { BinanceInvoiceContext } from "./BinanceInvoiceLoader";
import BinanceCurrenciesMenu from "./components/BinanceCurrenciesMenu";
import BinanceStep from "./components/BinanceStep";
import BinanceStepDescription from "./components/BinanceStepDescription";
import FrequentlyQuestion from "./components/FrequentlyQuestion";
import FrequentlyQuestionsGroup from "./components/FrequentlyQuestionsGroup";


const BinanceCurrencyPart = ({ onGotCheck }: { onGotCheck: (check: BinanceCheck) => void }) => {
    const invoice = useContext(BinanceInvoiceContext).invoice;
    const [ticker, setTicker] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleTickerChanged = (newTicker: string) => {
        setTicker(newTicker);
    };

    let active = true;

    useEffect(() => {
        return () => {
            active = false;
        };
    }, [active]);

    const handleNext = () => {
        async function fetch() {
            const tickerLocal = ticker;
            if (tickerLocal === null) return;
            if (!active) return;
            setLoading(true);

            try {
                await (new Promise((resolve) => setTimeout(resolve, 1_000)));
                const response = await axios.post(
                    `https://api.neutrino.plus/v7/rebirth/purchases/binance/precise/lock?token=${invoice.token}&currency=${tickerLocal}`
                )
                console.log(response);
                if (response.status === 200) {
                    const data: {
                        cryptoAmount: string,
                        cryptoCurrency: string,
                        fiatAmount: string,
                        fiatCurrency: string,
                        payId: string,
                        ttlSeconds: number,
                    } = response.data;
                    const check = new BinanceCheck({
                        createdAt: new Date(),
                        expiredAt: new Date((new Date()).valueOf() + (data.ttlSeconds * 1_000)),
                        cryptoAmount: parseFloat(data.cryptoAmount),
                        cryptoCurrency: data.cryptoCurrency,
                        fiatAmount: parseFloat(data.fiatAmount),
                        fiatCurrency: data.fiatCurrency,
                        payId: data.payId,
                        ttlSeconds: data.ttlSeconds,
                    });
                    onGotCheck(check);
                }
            } catch (e) {
                console.error(e);
            } finally {
                if (active) {
                    setLoading(false);
                }
            }
        }

        fetch();
    };

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <BinanceStep num={1} count={2} />
        <Box sx={{ height: '24px' }} />
        <BinanceStepDescription>
            <FormattedMessage id="binance.select_coin.description" />
        </BinanceStepDescription >
        <Box sx={{ height: '24px' }} />
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            marginLeft: '24px',
            marginRight: '24px',
        }}>
            <Typography variant="subtitle1" sx={{ opacity: 0.57 }}>
                <FormattedMessage id="binance.select_coin.amount_of_fiat" />
            </Typography>
            <Box sx={{ height: '8px' }} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <Typography variant="body1" sx={{ fontWeight: '700' }}>
                    <FormattedNumber value={invoice.fiatAmount} style="currency" currency={invoice.fiatCurrency} />
                </Typography>
                <Box sx={{ width: '4px' }} />
            </Box>
        </Box>
        <Box sx={{ height: '24px' }} />
        <BinanceCurrenciesMenu onChange={handleTickerChanged} tickers={invoice.tickers} />
        <FrequentlyQuestionsGroup>
            <FrequentlyQuestion
                questionId="binance.help.want_other_currency.question"
                answerId="binance.help.want_other_currency.answer" />
        </FrequentlyQuestionsGroup>
        <StyledFlatButton handleClick={handleNext} loading={loading} disabled={ticker === null}>
            <FormattedMessage id="btn.continue" />
        </StyledFlatButton>
    </Box>
};

export default BinanceCurrencyPart;