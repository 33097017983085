import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { createContext, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { LocalizationManagerContext } from "../../../lang/Localization";
import FixRtl from "../../../components/FixRtl";

export const BinanceStepContext = createContext({
    get back(): () => void { throw Error('Not Implemented'); }
});

const BinanceStep = ({
    num,
    count
}: {
    num: number,
    count: number,
}) => {
    const context = useContext(BinanceStepContext);
    const handleBack = () => {
        context.back();
    };
    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: '16px',
        marginRight: '16px',
    }}>
        <IconButton onClick={handleBack}>
            <FixRtl>
                <ArrowBackRoundedIcon />
            </FixRtl>
        </IconButton>
        <Typography variant="h5"
            sx={{
                left: 0,
                position: 'relative',
                textAlign: 'start',
                marginLeft: '8px',
                marginRight: '8px',
            }}>
            <FormattedMessage id="step_pattern" values={{
                n: num,
                count: count,
            }} />
        </Typography>
    </Box>;
};

export default BinanceStep;