import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import BinancePage from './pages/binance/BinancePage';
import NotFound from './pages/NotFound';
import { BrowserRouter } from 'react-router-dom';
import Localization from './lang/Localization';
import PhonepePage from './pages/phonepe/PhonepePage';


// eslint-disable-next-line no-prototype-builtins, @typescript-eslint/no-explicit-any
if (!new class { x: any }().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');

const theme = responsiveFontSizes(createTheme({
  typography: {
    fontFamily: [
      'Mulish',
    ].join(','),
    button: {
      fontFamily: 'Mulish',
      fontWeight: 900,
    }
  },
}));

// "transaction.waiting.title": "Waiting on Payment...",
// "transaction.time_is_over.title": "Time is Over",
// "transaction.btn.copy_address": "Copy Address",
// "transaction.btn.cancel_payment": "Cancel Payment",
// "transaction.btn.close": "Close",
// "transaction.btn.contact_us": "Contact us",
// "transaction.snackbar.address_copied": "Address copied! (Try <a>@wallet</a>)",
// "transaction.expired.msg.title": "Payment Expired!",
// "transaction.expired.msg.body": "We haven't received a TON from you. If you think you sent one, please contact us.",
// "transaction.paid.msg.title": "Success!",
// "transaction.paid.msg.body": "You can now close this page and return to the application. Don't forget to update your balance.",

const queryParams = new URLSearchParams(window.location.search);


interface MainQueryParams {
  readonly invoiceId: string,
  readonly methodName: 'binance' | 'phonepe' | 'undefined',
}

const params: MainQueryParams | null = (() => {
  if (queryParams.has('i')) {
    const invoiceId = queryParams.get('i');
    const m = queryParams.get('m')?.toLowerCase();
    if (invoiceId != null) {
      const parseMethodName = () => {
        switch (m) {
          case 'b':
            return 'binance';
          case 'p':
            return 'phonepe';
          default:
            return 'binance';
        }
      }
      const queryParams: MainQueryParams = {
        invoiceId: invoiceId,
        methodName: parseMethodName(),
      };
      sessionStorage.setItem('params', JSON.stringify(queryParams));
      return queryParams;
    }
    return null;
  }
  const serializedParams = sessionStorage.getItem('params');
  if (serializedParams != null) {
    try {
      const params = JSON.parse(serializedParams) as MainQueryParams;
      return {
        invoiceId: params.invoiceId,
        methodName: params.methodName,
      };
    } catch (e) {
      console.error(e);
    }
  }
  return null;
})();


const ConfigComponent = ({ children }: { children: JSX.Element }) => {
  return (<React.StrictMode>
    <Localization>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {children}
        </BrowserRouter>
      </ThemeProvider>
    </Localization>
  </React.StrictMode >);
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const render = (component: JSX.Element) => {
  root.render(<ConfigComponent>{component}</ConfigComponent>);
};

// jEkmJfPIJmMEnQmEBEco--CkNr5AFgTZvJ81jKKSUiA

if (params == null) {
  render(<NotFound />);
} else {
  switch (params.methodName) {
    case 'binance':
      render(<BinancePage invoiceId={params.invoiceId} />);
      break;
    case 'phonepe':
      render(<PhonepePage invoiceId={params.invoiceId} />);
      break;
    default:
      render(<NotFound />);
      break;
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
