import { createContext } from "react";

interface Env {
    readonly serviceName: string;
    readonly serviceLink: string;
    readonly supportLink: string;
    readonly binanceReferralLink: string,
}

const EnvContext = createContext<Env>({
    serviceName: 'upayme.cash',
    serviceLink: 'https://upayme.cash',
    supportLink: 'https://t.me/neutrino_plus',
    binanceReferralLink: 'https://accounts.binance.com/en/register?ref=IZ9AFKX8'
});

export default EnvContext;