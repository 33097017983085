import { Box, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { LocalizationManagerContext } from "../lang/Localization";

const Language = ({
    lang,
    selected,
    handleChange,
    children
}: {
    lang: string,
    selected: boolean,
    handleChange: (lang: string) => void,
    children: string | JSX.Element
}) => {
    const theme = useTheme();
    return <Typography onClick={() => handleChange(lang)} sx={
        selected
            ? {
                color: theme.palette.primary.dark,
                fontWeight: 700,
            }
            : {
                color: '#000000',
                '&:hover': {
                    color: theme.palette.primary.dark,
                    textDecoration: 'underline',
                },
            }}>{children}</Typography>;
}

const LanguageSeparator = () => {
    return <Typography>&nbsp;&nbsp;&nbsp;</Typography>;
}
// ₚ
const SelectLanguage = () => {
    const localizationManager = useContext(LocalizationManagerContext).manager;
    const appLang = localizationManager.appLang;
    const appLanguages = localizationManager.appLanguages;
    const handleChange = localizationManager.changeLang;
    const items: JSX.Element[] = [];

    for (let i = 0; i < (appLanguages.length * 2 - 1); ++i) {
        if (i % 2 === 0) {
            const lang = appLanguages[Math.floor(i / 2)];
            items.push((
                <Language key={`lang-item-${lang.code}`} lang={lang.code} selected={appLang.code === lang.code} handleChange={handleChange}>{lang.name}</Language>
            ));
        } else {
            items.push((
                <LanguageSeparator key={`lang-item-separator-${i}`} />
            ));
        }
    }

    return <Box sx={{
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '48px',
        paddingRight: '48px',
    }}>
        {items}
    </Box>
};

export default SelectLanguage;