import { Box, Link, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import LockIcon from '@mui/icons-material/Lock';
import { green } from "@mui/material/colors";
import { useContext } from "react";
import EnvContext from "../../Env";

const Footer = () => {
    const env = useContext(EnvContext);
    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }}>
        <LockIcon sx={{
            fontSize: '18px',
            paddingRight: '6px',
            color: green[300],
        }} />
        <Typography color='#ffffff88'>
            <FormattedMessage id="footer.title" values={{
                a: chunk => (
                    <Link href={env.serviceLink} target="_blank" sx={{ color: '#07A0EC' }}>
                        {chunk}
                    </Link>
                ),
                url: env.serviceName,
            }} />
        </Typography>
    </Box>;
};

export default Footer;