import { Box, Typography } from "@mui/material";
import { BinanceLogo } from "../../../icons/CryptoIcons";

const BinanceTitle = () => {
    return <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <BinanceLogo size={42} />
        <Box sx={{ width: '8px' }} />
        <Typography variant="h4" sx={{
            textTransform: 'uppercase', fontWeight: '700', color: 'rgb(243, 186, 47)'
        }}>Binance</Typography>
    </Box>
};

export default BinanceTitle;