class BinanceCheck {
    readonly cryptoAmount: number;
    readonly cryptoCurrency: string;
    readonly fiatAmount: number;
    readonly fiatCurrency: string;
    readonly payId: string;
    readonly ttlSeconds: number;
    readonly createdAt: Date;
    readonly expiredAt: Date;

    constructor(args: {
        cryptoAmount: number,
        cryptoCurrency: string,
        fiatAmount: number,
        fiatCurrency: string,
        payId: string,
        ttlSeconds: number,
        createdAt: Date,
        expiredAt: Date,
    }) {
        this.cryptoAmount = args.cryptoAmount;
        this.cryptoCurrency = args.cryptoCurrency;
        this.fiatAmount = args.fiatAmount;
        this.fiatCurrency = args.fiatCurrency;
        this.payId = args.payId;
        this.ttlSeconds = args.ttlSeconds;
        this.createdAt = args.createdAt;
        this.expiredAt = args.expiredAt;
    }
}

export default BinanceCheck;