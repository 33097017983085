import { CircularProgress } from "@mui/material";

const PageProgress = () => {
    return <div style={{ textAlign: 'center' }}>
        <CircularProgress sx={{
            marginTop: '48px',
            marginBottom: '48px',
        }} />
    </div>;

};

export default PageProgress;