import { Box, Button, CircularProgress, styled } from "@mui/material";
import { useEffect, useState } from "react";

const StyledButton = styled(Button)((({ theme }) => ({
    borderRadius: '24px',
    paddingTop: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '12px',
    marginLeft: '24px',
    marginRight: '24px',
    minWidth: '60%',
    // '&:hover': {
    //     backgroundColor: 'black',
    // },
    // '&:active': {
    //     backgroundColor: 'black',
    // },
    // '& .MuiButton': {
    //     backgroundColor: '#000',
    // },
    // '& .MuiButton-root': {
    //     color: "white",
    //     backgroundColor: '#000',
    //     // backgroundColor: '#0088CC',
    // },
    // MuiButton: {
    //     root: {
    //         backgroundColor: '#000',
    //     },
    // },
})));

const StyledFlatButton = ({
    handleClick,
    loading,
    disabled,
    children,
    margins,
}: {
    handleClick?: () => void | null,
    loading?: boolean | null,
    disabled?: boolean | null,
    children: any,
    margins?: boolean | null,
}) => {
    const [btnDisabled, setBtnDisabled] = useState(loading === true || disabled === true)
    useEffect(() => {
        setBtnDisabled(loading === true || disabled === true);
    }, [loading, disabled]);
    return <StyledButton variant="contained" size='large' disableElevation
        sx={{
            marginLeft: (margins ?? true) === true ? '24px' : '0px',
            marginRight: (margins ?? true) === true ? '24px' : '0px',
        }}
        onClick={handleClick}
        disabled={btnDisabled}>
        {loading === true ? <CircularProgress size={'24px'} sx={{ color: 'rgba(0, 0, 0, 0.2)' }} /> : <div />}
        {loading === true ? <Box sx={{ width: '16px' }} /> : <div />}
        {children}
    </StyledButton>;
};

export default StyledFlatButton;