import { Box, Typography } from "@mui/material";
import ContentCard from "../../components/content/ContentCard";
import BinanceIntroPart from "./BinanceIntroPart";
import BinanceCurrencyPart from "./BinanceCurrenyPart";
import BinanceCheckPart from "./BinanceCheckPart";
import BinanceTitle from "./components/BinanceTitle";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import StyledPage from "../../components/StyledPage";
import PageProgress from "../../components/PageProgress";
import { BinanceStepContext } from "./components/BinanceStep";
import BinanceInvoiceLoader, { BinanceInvoiceContext } from "./BinanceInvoiceLoader";
import BinanceCheck from "./BinanceCheck";
import { green, grey, red } from "@mui/material/colors";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import StyledFlatButton from "../../components/buttons/StyledFlatButton";
import EnvContext from "../../Env";
import { FormattedMessage } from "react-intl";
import ThemeWrapper from "../../components/ThemeWrapper";
import { ConctactUsButton } from "../../components/ContactUsButton";

const BINANCE_PRIMARY_COLOR = '#FCD535';
const BINANCE_PRIMARY_DARK_COLOR = '#F3BA2F';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false; // removes the `xs` breakpoint
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        desktop: true;
    }
}

const CommonContent = ({ children }: { children: JSX.Element }) => {
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
        <ContentCard>
            <BinanceTitle />
            <Box sx={{ height: '16px' }} />
            <Box sx={{
                width: '100%', height: '8px',
                backgroundColor: grey[100],
            }} />
            <Box sx={{ height: '16px' }} />
            {children}
        </ContentCard>
    </Box>;
}

const BinanceIntroContent = ({ onStart }: { onStart: () => void }) => {
    return <CommonContent><BinanceIntroPart onStart={onStart} /></CommonContent>;
};

const BinanceCurrencyContent = ({ onGotCheck }: { onGotCheck: (check: BinanceCheck) => void }) => {
    return <CommonContent><BinanceCurrencyPart onGotCheck={onGotCheck} /></CommonContent>;
};

const BinanceCheckContent = ({ check, onSuccess, onExpired }: { check: BinanceCheck, onSuccess: () => void, onExpired: () => void }) => {
    return <CommonContent><BinanceCheckPart check={check} onSuccess={onSuccess} onExpired={onExpired} /></CommonContent>
};

const BinanceSuccessContent = () => {
    return <CommonContent>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '24px',
            paddingRight: '24px',
        }}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CheckCircleIcon sx={{
                        color: green[400],
                        fontSize: '32px',
                    }} />
                    <Box sx={{ width: '8px' }} />
                    <FormattedMessage id="common.success.title" />
                </Box>
            </Typography>
            <Box sx={{ height: '16px' }} />
            <Typography variant="body1">
                <FormattedMessage id="common.success.message" />
            </Typography>
            <Box sx={{ height: '24px' }} />
            <ConctactUsButton />
        </Box>
    </CommonContent>
};

const BinanceExpiredContent = () => {
    return <CommonContent>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '24px',
            paddingRight: '24px',
        }}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <TimerRoundedIcon sx={{
                        color: red[400],
                        fontSize: '32px',
                    }} />
                    <Box sx={{ width: '8px' }} />
                    <FormattedMessage id="binance.expired.title" />
                </Box>
            </Typography>
            <Box sx={{ height: '16px' }} />
            <Typography variant="body1">
                <FormattedMessage id="binance.expired.message" />
            </Typography>
            <Box sx={{ height: '24px' }} />
            <ConctactUsButton />
        </Box>
    </CommonContent>
};

const InvoiceNumberTitle = () => {
    const invoice = useContext(BinanceInvoiceContext).invoice;
    return <Typography sx={{
        color: '#ffffff88',
    }}><FormattedMessage id="invoice.title" values={{ n: invoice.id }} /></Typography>;
};

interface PaymentState {
    readonly started: boolean,
    readonly check: BinanceCheck | null,
    readonly success: boolean,
    readonly expired: boolean,
}

const PageStarter = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('../start', { replace: true });
    });
    return <PageProgress />;
};

const BinancePage = ({ invoiceId }: { invoiceId: string }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [state, setState] = useState<PaymentState>({
        started: false,
        check: null,
        success: false,
        expired: false,
    });

    useEffect(() => {
        const pathname = location.pathname;

        if (pathname.endsWith('/check')) {
            if (state.check == null) {
                navigate('../currency')
            }
        }
        console.log(`pathname: ${pathname}`);
    });

    const handleStart = () => {
        setState({ ...state, started: true });
        navigate('../currency')
    };

    const handleGotCheck = (check: BinanceCheck) => {
        setState({ ...state, check: check });
        navigate('../check')
    };

    const handleSuccess = () => {
        setState({ ...state, success: true });
        navigate('../success', { replace: true })
    };

    const handleExpired = () => {
        setState({ ...state, expired: true });
        navigate('../expired', { replace: true })
    };

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <ThemeWrapper palette={{
            primary: BINANCE_PRIMARY_COLOR,
            primaryDark: BINANCE_PRIMARY_DARK_COLOR,
        }}>
            <StyledPage>
                <BinanceInvoiceLoader
                    progressComponent={<PageProgress />}
                    invoiceToken={invoiceId}
                    invoiceLanguage={'en'}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <InvoiceNumberTitle />
                        <Box sx={{ height: { mobile: '8px', tablet: '24px' } }} />
                        <BinanceStepContext.Provider value={{ back: handleBack }}>
                            <Routes>
                                <Route path="/" element={<PageStarter />} />
                                <Route path="/start" element={<BinanceIntroContent onStart={handleStart} />} />
                                <Route path="/currency" element={<BinanceCurrencyContent onGotCheck={handleGotCheck} />} />
                                <Route path="/check" element={
                                    <BinanceCheckContent check={state.check!}
                                        onSuccess={handleSuccess}
                                        onExpired={handleExpired}
                                    />}
                                />
                                <Route path="/success" element={<BinanceSuccessContent />} />
                                <Route path="/expired" element={<BinanceExpiredContent />} />
                            </Routes>
                        </BinanceStepContext.Provider>
                    </Box>
                </BinanceInvoiceLoader>
            </StyledPage>
        </ThemeWrapper>
    );
};

export default BinancePage;

