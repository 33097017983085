import axios from "axios";
import { createContext, useEffect, useState } from "react";

class BinanceInvoice {
    readonly id: string;
    readonly token: string;
    readonly language: string;
    readonly sku: string;
    readonly fiatAmount: number;
    readonly fiatCurrency: string;
    readonly tickers: Set<string>;
    readonly successURL: string;
    readonly failureURL: string;

    constructor(args: {
        id: string;
        token: string,
        language: string,
        sku: string,
        fiatAmount: number,
        fiatCurrency: string,
        tickers: Set<string>,
        successURL: string,
        failureURL: string,
    }) {
        this.id = args.id;
        this.token = args.token;
        this.language = args.language;
        this.sku = args.sku;
        this.fiatAmount = args.fiatAmount;
        this.fiatCurrency = args.fiatCurrency;
        this.tickers = args.tickers;
        this.successURL = args.successURL;
        this.failureURL = args.failureURL;
    }
}

export const BinanceInvoiceContext = createContext({
    get invoice(): BinanceInvoice { throw Error('Context not provided!') }
});

const BinanceInvoiceLoader = ({
    invoiceToken,
    invoiceLanguage,
    progressComponent,
    children,
}: {
    invoiceToken: string,
    invoiceLanguage: string,
    progressComponent: JSX.Element,
    children: JSX.Element,
}) => {
    const [invoice, setInvoice] = useState<BinanceInvoice | null>(null);

    useEffect(() => {
        let active = true;

        async function fetch() {
            while (active && invoice === null) {
                console.log('REQUEST');
                try {
                    const response = await axios.post(
                        `https://api.neutrino.plus/v7/rebirth/purchases/binance/precise/invoice?token=${invoiceToken}&language=${invoiceLanguage}`
                    )
                    console.log(response)
                    if (!active) break;
                    if (response.status === 200) {
                        const data: {
                            id: string,
                            sku: string,
                            fiatAmount: string,
                            fiatCurrency: string,
                            cryptoCurrencies: string[],
                            successURL: string,
                            failURL: string,
                        } = response.data;

                        setInvoice(new BinanceInvoice({
                            id: data.id,
                            token: invoiceToken,
                            language: invoiceLanguage,
                            sku: data.sku,
                            fiatAmount: parseFloat(data.fiatAmount),
                            fiatCurrency: data.fiatCurrency,
                            tickers: new Set(data.cryptoCurrencies),
                            successURL: data.successURL,
                            failureURL: data.failURL,
                        }));
                        break;
                    }
                } catch (e) {
                    console.error(e);
                }

                await (new Promise((resolve) => setTimeout(resolve, 2_000)));
            }
        }
        fetch();
        return () => {
            active = false;
        };
    }, [invoiceToken, invoiceLanguage]);

    if (invoice === null) {
        return progressComponent;
    }
    return <BinanceInvoiceContext.Provider value={{ invoice: invoice }}>{children}</BinanceInvoiceContext.Provider>
};

export default BinanceInvoiceLoader;