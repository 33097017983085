import { Box } from "@mui/material";

const OverlayProgress = ({
    children,
}: {
    children: JSX.Element | JSX.Element[] | any | null,
}) => {
    return <Box>{children}</Box>;
};

export default OverlayProgress;