import { createContext, useState } from "react";
import { IntlProvider } from "react-intl";
import defaultMessages from './strings/en.json';

interface LangDeclaration {
    readonly name: string;
    readonly code: string;
    readonly rtl: boolean;
}

/**
 * Порядок языков соответствует важности
 */

const SUPPORTED_LANGS: LangDeclaration[] = [
    { name: 'English', code: 'en', rtl: false },
    { name: 'Українська', code: 'uk', rtl: false },
    { name: 'ₚусский', code: 'ru', rtl: false },
    { name: 'Español', code: 'es', rtl: false },
    { name: 'Italiano', code: 'it', rtl: false },
    { name: 'Türkçe', code: 'tr', rtl: false },
    { name: 'Bahasa', code: 'id', rtl: false },
    { name: 'हिन्दी', code: 'hi', rtl: false },
    { name: 'العربية', code: 'ar', rtl: true },
    { name: 'زبان فارسی', code: 'fa', rtl: true },
];
const DEF_LANG = SUPPORTED_LANGS[0];
const LANG_CODES: string[] = SUPPORTED_LANGS.map((v) => v.code);
const RTL_LANGS: string[] = SUPPORTED_LANGS.filter((v) => v.rtl).map((v) => v.code);

function getBrowserLocales(options = {}) {
    const defaultOptions = {
        languageCodeOnly: false,
    };
    const opt = {
        ...defaultOptions,
        ...options,
    };
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
    if (!browserLocales) {
        return undefined;
    }
    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
    });
}

const getLang = (): string => {
    try {
        const locales = getBrowserLocales()
        if (locales === undefined) return DEF_LANG.code;
        if (locales.length >= 1) {
            let lang = locales[0].toLowerCase();
            if (lang.length > 2) {
                lang = lang.substring(0, 2);
            }
            if ((new Set(LANG_CODES)).has(lang)) {
                return lang;
            }
            return DEF_LANG.code;
        }
    } catch (e) {
        console.error(e);
    }
    return DEF_LANG.code;
}

export const isRtl = (new Set(RTL_LANGS)).has(getLang() as string);

export const getDirection = () => isRtl ? 'rtl' : 'ltr';

const getMessages = (lang: string) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    // import(`./strings/${DEF_LANG.code}.json`).then(messages => {

    // });
    if (lang === 'en') {
        return defaultMessages;
    }
    if (!(new Set(LANG_CODES)).has(lang)) {
        return defaultMessages;
    }
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return Object.assign({}, defaultMessages, require(`./strings/${lang}.json`));
}

export interface LocalizationManager {
    readonly appLang: LangDeclaration;
    readonly appLanguages: LangDeclaration[];
    readonly defaultLang: LangDeclaration;
    readonly changeLang: (lang: string) => void;
}

export const LocalizationManagerContext = createContext({
    get manager(): LocalizationManager {
        throw Error(`Provider[${LocalizationManagerContext}] not installed!`)
    }
});

const Localization = ({ children, }: { children: JSX.Element }) => {
    const [appLang, setAppLang] = useState((() => {
        let lang = localStorage.getItem('__app.lang__');
        if (lang === null || !(new Set(LANG_CODES)).has(lang)) {
            lang = getLang();
        }
        return lang;
    })());
    if (document.documentElement.lang !== appLang) {
        document.documentElement.lang = appLang;
    }
    const isRtl = (new Set(RTL_LANGS)).has(appLang);
    const direction = isRtl ? 'rtl' : 'ltr';
    if (document.dir !== direction) {
        document.dir = direction;
    }
    const changeLang = (lang: string) => {
        console.log('Change language to: ' + lang);
        if (lang === appLang) return;
        if (!(new Set(LANG_CODES)).has(lang)) return;
        localStorage.setItem('__app.lang__', lang);
        setAppLang(lang);
    };
    return (
        <LocalizationManagerContext.Provider value={{
            manager: {
                appLang: SUPPORTED_LANGS.filter((v) => v.code == appLang)[0],
                appLanguages: SUPPORTED_LANGS,
                defaultLang: DEF_LANG,
                changeLang: changeLang,
            }
        }}>
            <IntlProvider messages={getMessages(appLang)} defaultLocale={DEF_LANG.code} locale={appLang}>
                {children}
            </IntlProvider>
        </LocalizationManagerContext.Provider>
    );
};

export default Localization;