import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { BnbIcon, BtcIcon, BusdIcon, DashIcon, DogeIcon, EthIcon, LtcIcon, NeoIcon, OneInchIcon, ShibaIcon, SolanaIcon, UsdcIcon, UsdtIcon, XmrIcon, ZecIcon } from "../../../icons/CryptoIcons";
import React from 'react';

interface BinanceCurrencyInfo {
    readonly ticker: string,
    readonly title: string,
    readonly icon: JSX.Element,
}


// cryptocons: icons
const items: BinanceCurrencyInfo[] = [
    { ticker: 'USDT', title: 'Tether', icon: <UsdtIcon /> },
    { ticker: 'BTC', title: 'Bitcoin', icon: <BtcIcon /> },
    { ticker: 'LTC', title: 'Litecoin', icon: <LtcIcon /> },
    { ticker: 'ETH', title: 'Ethereum', icon: <EthIcon /> },
    { ticker: 'BNB', title: 'Binance Coin', icon: <BnbIcon /> },
    { ticker: 'BUSD', title: 'Binance USD', icon: <BusdIcon /> },
    { ticker: 'USDC', title: 'Coinbase USD', icon: <UsdcIcon /> },
    { ticker: 'DOGE', title: 'Doge', icon: <DogeIcon /> },
    { ticker: 'XMR', title: 'Monero', icon: <XmrIcon /> },
    { ticker: 'SOL', title: 'Solana', icon: <SolanaIcon /> },
    { ticker: 'SHIB', title: 'Shiba Inu', icon: <ShibaIcon /> },
    { ticker: 'DASH', title: 'Dash', icon: <DashIcon /> },
    { ticker: 'ZEC', title: 'ZCash', icon: <ZecIcon /> },
    { ticker: 'NEO', title: 'Neo', icon: <NeoIcon /> },
    { ticker: '1INCH', title: '1inch', icon: <OneInchIcon /> },
];

const BinanceCurrenciesMenu = ({
    tickers,
    onChange,
}: {
    tickers: Set<string>,
    onChange?: (ticker: string) => void
}) => {
    const handleChange = (event: SelectChangeEvent) => {
        if (onChange != null) {
            onChange(event.target.value);
        }
    }
    const availableItems = items.filter((item) => tickers.has(item.ticker));

    return <Box sx={{
        paddingLeft: '24px',
        paddingRight: '24px',
    }}>
        <FormControl fullWidth>
            <InputLabel id="input-binance_currency_name">
                <FormattedMessage id="binance.select_coin.field.currency.hint" />
            </InputLabel>
            <Select
                id="select-binance_currency_name"
                label={<FormattedMessage id="binance.select_coin.field.currency.hint" />}
                labelId="input-binance_currency_name"
                onChange={handleChange}>
                {availableItems.map(
                    item => <MenuItem key={item.title} value={item.ticker}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            {item.icon}
                            <Box sx={{ width: '16px' }} />
                            {item.ticker} ({item.title})
                        </Box>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    </Box>;
};
export default BinanceCurrenciesMenu;