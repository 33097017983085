import { Paper } from "@mui/material";
import OverlayProgress from "../OverlayProgress";

const ContentCard = ({
    children
}: {
    children: JSX.Element | JSX.Element[] | any
}) => {
    return <Paper sx={{
        paddingTop: '24px',
        paddingBottom: '24px',
        maxWidth: '480px',
        minWidth: { mobile: '100vw', tablet: '480px' },
        display: 'flex',
        flexDirection: 'column',
        marginLeft: { mobile: '0px', tablet: '24px' },
        marginRight: { mobile: '0px', tablet: '24px' },
        marginTop: '4px',
        marginBottom: '4px',
        borderRadius: '16px',
    }}>
        <OverlayProgress>
            {children}
        </OverlayProgress>
    </Paper>;
};

export default ContentCard;