import { Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import StyledFlatButton from "../../components/buttons/StyledFlatButton";
import { BinanceInvoiceContext } from "./BinanceInvoiceLoader";
import FrequentlyLinkQuestion from "./components/FrequentlyLinkQuestion";
import FrequentlyQuestion from "./components/FrequentlyQuestion";
import FrequentlyQuestionsGroup from "./components/FrequentlyQuestionsGroup";
import TelegramIcon from '@mui/icons-material/Telegram';
import EnvContext from "../../Env";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SelectLanguage from "../../components/SelectLanguage";
import { grey } from "@mui/material/colors";
import FixRtl from "../../components/FixRtl";


const BinanceIntroPart = ({ onStart }: { onStart: () => void }) => {
    const env = useContext(EnvContext);
    const invoice = useContext(BinanceInvoiceContext).invoice;
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <SelectLanguage />
        <Box sx={{ height: '16px' }} />
        <Box sx={{
            width: '100%', height: '8px',
            backgroundColor: grey[100],
        }} />
        <Box sx={{ height: '16px' }} />
        <Stepper orientation="vertical" sx={{
            marginLeft: '24px',
            marginRight: '24px',
        }}>{
                [<Step key={`binance.instruction.step1`} active={true}>
                    <StepLabel><FormattedMessage id={`binance.instruction.step1.title`} /></StepLabel>
                    <StepContent>
                        <Typography sx={{
                            alignContent: 'start',
                            textAlign: 'start',
                        }}>{Array.from(invoice.tickers).join(', ')}</Typography>
                    </StepContent>
                </Step>,
                ...[2, 3].map(num => <Step key={`binance.instruction.step${num}`} active={true}>
                    <StepLabel><FormattedMessage id={`binance.instruction.step${num}.title`} /></StepLabel>
                    <StepContent>
                        <Typography sx={{
                            alignContent: 'start',
                            textAlign: 'start',
                        }}><FormattedMessage id={`binance.instruction.step${num}.body`} /></Typography>
                    </StepContent>
                </Step>),]
            }
        </Stepper>
        <FrequentlyQuestionsGroup>
            <FrequentlyQuestion questionId="binance.help.if_no_binance.question" answerId="binance.help.if_no_binance.answer" />
            <FrequentlyLinkQuestion questionId="binance.help.open_binance_account" icon={<FixRtl><OpenInNewIcon /></FixRtl>} link={env.binanceReferralLink} />
            <FrequentlyLinkQuestion questionId="binance.help.ask_in_telegram" icon={<FixRtl><TelegramIcon /></FixRtl>} link={env.supportLink} />
        </FrequentlyQuestionsGroup>
        <StyledFlatButton handleClick={onStart}><FormattedMessage id="btn.start" /></StyledFlatButton>
    </Box>;
}

export default BinanceIntroPart;