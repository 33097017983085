import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import StyledFlatButton from "../../components/buttons/StyledFlatButton";
import BinanceStep from "./components/BinanceStep";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import BinanceCheck from "./BinanceCheck";
import { FormattedMessage, useIntl } from "react-intl";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { copyToClipboard } from "../../utils/Clipboard";
import axios from "axios";
import { BinanceInvoiceContext } from "./BinanceInvoiceLoader";
import BinanceStepDescription from "./components/BinanceStepDescription";
import FrequentlyQuestionsGroup from "./components/FrequentlyQuestionsGroup";
import FrequentlyLinkQuestion from "./components/FrequentlyLinkQuestion";

const CopybleItem = ({
    title,
    value,
    children,
}: {
    title: string | JSX.Element,
    value: string,
    children?: JSX.Element | null,
}) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        copyToClipboard(value);
        setOpen(true);
    };
    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return <div>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            marginLeft: '24px',
            marginRight: '24px',
        }}>
            <Typography variant="subtitle1" sx={{ opacity: 0.57 }}>{title}</Typography>
            <Box sx={{ height: '8px' }} />
            <Box onClick={handleClick}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                <Typography variant="body1" sx={{
                    fontWeight: '700',
                }}>{children ?? value}</Typography>
                <Box sx={{ width: '4px' }} />
                <IconButton onClick={handleClick}><ContentCopyRoundedIcon /></IconButton>
            </Box>
        </Box>
        <Snackbar
            open={open}
            autoHideDuration={1000}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                <FormattedMessage id="msg.copied" />
            </Alert>
        </Snackbar>
    </div>;
};

const BinanceCheckPart = ({
    check,
    onSuccess,
    onExpired,
}: {
    check: BinanceCheck,
    onSuccess: () => void,
    onExpired: () => void,
}) => {
    const intl = useIntl();
    const remainsMillis = () => {
        return Math.max(0, check.expiredAt.valueOf() - (new Date()).valueOf());
    };
    const computeTime = (): string | null => {
        const millis = remainsMillis();

        if (millis === 0) {
            return null;
        }

        const m = Math.floor(millis / (1_000 * 60));
        const s = Math.floor((millis % (1_000 * 60)) / 1_000);
        return `${m < 10 ? `0${m}` : `${m}`}:${s < 10 ? `0${s}` : `${s}`}`;
    };

    const invoice = useContext(BinanceInvoiceContext).invoice;
    const abortControllerRef = useRef<AbortController>();
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(computeTime())

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        }
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const timeUpdate = computeTime();
            if (timeUpdate === null) {
                onExpired();
            } else {
                setTime(timeUpdate);
            }
        }, 1_000);
        return () => {
            clearTimeout(timeoutId);
        };
    });

    const handleCheck = () => {
        if (loading) return;
        const abort = new AbortController();
        abortControllerRef.current = abort;

        const isActive = () => abortControllerRef.current != null;

        const request = async () => {
            if (!isActive()) return;
            setLoading(true);
            try {
                for (let i = 0; i < 6; ++i) {
                    const response = await axios.post(
                        `https://api.neutrino.plus/v7/rebirth/purchases/binance/precise/confirm?token=${invoice.token}`,
                        { signal: abort, }
                    );

                    if (response.status === 200) {
                        const { status } = response.data;
                        if (status === 'ok') {
                            onSuccess();
                            break;
                        }
                    }

                    await (new Promise((resolve) => setTimeout(resolve, 5_000)));
                }
            } catch (e) {
                console.error(e);
            } finally {
                if (isActive()) {
                    setLoading(false);
                }
            }
        }
        request();
    };

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <BinanceStep num={2} count={2} />
        <Box sx={{ height: '24px' }} />
        <BinanceStepDescription>
            <FormattedMessage id="binance.check.description" values={{
                ticker: `${check.cryptoCurrency}`,
            }} />
        </BinanceStepDescription>
        <Box sx={{ height: '24px' }} />
        <CopybleItem title={
            <FormattedMessage id="binance.check.crypto" values={{
                ticker: `${check.cryptoCurrency}`,
            }} />
        } value={`${check.cryptoAmount}`} />
        <Box sx={{ height: '24px' }} />
        <CopybleItem title={
            <FormattedMessage id="binance.check.payment_id" />
        } value={`${check.payId}`} />
        <Box sx={{ height: '24px' }} />
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            marginLeft: '24px',
            marginRight: '24px',
        }}>
            <Typography variant="subtitle1" sx={{ opacity: 0.57 }}>
                <FormattedMessage id="binance.check.time_for_payment" />
            </Typography>
            <Box sx={{ height: '8px' }} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <Typography variant="body1" sx={{ fontWeight: '700' }}>
                    {time}
                </Typography>
                <Box sx={{ width: '4px' }} />
            </Box>
        </Box>
        <FrequentlyQuestionsGroup>
            <FrequentlyLinkQuestion questionId="binance.help.how_to_send" link={intl.formatMessage({ id: 'link.how_to_send' })} />
        </FrequentlyQuestionsGroup>
        <StyledFlatButton handleClick={handleCheck} loading={loading}>
            <FormattedMessage id="btn.check_payment" />
        </StyledFlatButton>
    </Box>
};

export default BinanceCheckPart;