import { Box } from "@mui/material";
import { Children, ReactNode } from "react";

const FrequentlyQuestionsGroup = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const items = Children.toArray(children);
    const itemsCount = items.length;
    const outputItems: Exclude<ReactNode, boolean | null | undefined>[] = [];

    for (let i = 0; i < itemsCount + itemsCount - 1; ++i) {
        if (i % 2 === 0) {
            outputItems.push(items[Math.floor(i / 2)]);
        } else {
            outputItems.push(<Box key={`${i}-8px-height`} sx={{ height: '8px' }} />);
        }
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '48px',
        marginBottom: '24px'
    }}>{outputItems}</Box>
};

export default FrequentlyQuestionsGroup;