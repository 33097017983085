import { createTheme, ThemeProvider } from "@mui/material";
import { green } from "@mui/material/colors";
import { useContext } from "react";
import { LocalizationManagerContext } from "../lang/Localization";

const ThemeWrapper = ({
    palette,
    children
}: {
    palette: {
        primary: string,
        primaryDark: string,
    },
    children: JSX.Element
}) => {
    const localizationManager = useContext(LocalizationManagerContext);
    const theme = createTheme({
        direction: localizationManager.manager.appLang.rtl ? 'rtl' : 'ltr',
        breakpoints: {
            values: {
                mobile: 0,
                tablet: 640,
                laptop: 1024,
                desktop: 1200,
            },
        },
        typography: {
            fontFamily: [
                'Mulish',
            ].join(','),
            button: {
                fontFamily: 'Mulish',
                fontWeight: 900,

            }
        },
        palette: {
            primary: {
                main: palette.primary,
            },
            secondary: {
                main: palette.primaryDark,
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        background: palette.primary,
                        "&:hover": {
                            background: palette.primaryDark,
                        },
                        "& .MuiButton-outlined": {
                            background: green[500],
                        }
                    },

                }
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        fill: palette.primary,
                    },
                    text: {
                        fill: 'black',
                        fontWeight: 700,
                    },
                }
            },
            MuiStepLabel: {
                styleOverrides: {
                    label: {
                        "&.Mui-active": {
                            fontWeight: 700,
                        },
                    },
                    root: {
                        color: palette.primary,
                        text: {
                            fontWeight: 700,
                        },
                    },

                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        '&.MuiLink-underlineAlways': {
                            color: palette.primary,
                        },
                    }
                },
            },
        }
    });

    return (<ThemeProvider theme={theme}>{children}</ThemeProvider>)
};

export default ThemeWrapper;