import { Card, CardHeader, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { grey } from "@mui/material/colors";
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

const FrequentlyLinkQuestion = ({
    question,
    questionId,
    link,
    icon,
}: {
    question?: string | JSX.Element | JSX.Element[] | null,
    questionId?: string | null,
    link: string,
    icon?: JSX.Element | null,
}) => {
    const [shadow, setShadow] = useState(false)
    function handleOnMouseOver() {
        setShadow(true);
    }
    function handleOnMouseOut() {
        setShadow(false);
    }
    function handleOpen() {
        window.open(link, '_blank', 'noopener,noreferrer');
    }
    return <Card onClick={handleOpen}
        elevation={0.0}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        sx={{
            marginLeft: '24px',
            marginRight: '24px',
            backgroundColor: grey[100],
            boxShadow: shadow ? 4.0 : 0.0,
        }}>
        <CardHeader
            sx={{
                textAlign: 'start',
            }}
            title={
                <Typography variant="body1" sx={{
                    fontWeight: '700',
                }}>
                    {question ?? <FormattedMessage id={questionId!} />}
                </Typography>
            }
            action={
                <IconButton
                    onClick={handleOpen}
                    aria-label="expand"
                    size="small" >
                    {icon ?? <LaunchRoundedIcon />}
                </IconButton>
            } />
    </Card >;
}

export default FrequentlyLinkQuestion;