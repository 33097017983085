import axios from "axios";
import { createContext, useEffect, useState } from "react";

class PhonePeInvoice {
    readonly id: string;
    readonly token: string;
    readonly url: string;
    readonly priceUSD: string;
    readonly priceINR: string;

    constructor(args: {
        id: string,
        token: string,
        url: string,
        priceUSD: string,
        priceINR: string,
    }) {
        this.id = args.id;
        this.token = args.token;
        this.url = args.url;
        this.priceUSD = args.priceUSD;
        this.priceINR = args.priceINR;
    }
}

export const PhonePeInvoiceContext = createContext({
    get invoice(): PhonePeInvoice { throw Error('Context not provided!') }
});

const PhonePeInvoiceLoader = ({
    invoiceToken,
    progressComponent,
    children,
}: {
    invoiceToken: string,
    progressComponent: JSX.Element,
    children: JSX.Element,
}) => {
    const [invoice, setInvoice] = useState<PhonePeInvoice | null>(null);

    useEffect(() => {
        let active = true;

        async function fetch() {
            while (active && invoice === null) {
                console.log('REQUEST');
                try {
                    const response = await axios.post(
                        `https://api.neutrino.plus/v7/rebirth/purchases/email/invoice?token=${invoiceToken}&merchant=phonepe`,
                    )
                    console.log(response)
                    if (!active) break;
                    if (response.status === 200) {
                        const data: {
                            id: string,
                            url: string,
                            priceUSD: string,
                            priceINR: string,
                        } = response.data;

                        setInvoice(new PhonePeInvoice({
                            id: data.id,
                            token: invoiceToken,
                            url: data.url,
                            priceUSD: data.priceUSD,
                            priceINR: data.priceINR,
                        }));
                        break;
                    }
                } catch (e) {
                    console.error(e);
                }

                await (new Promise((resolve) => setTimeout(resolve, 2_000)));
            }
        }
        fetch();
        return () => {
            active = false;
        };
    }, [invoiceToken]);

    if (invoice === null) {
        return progressComponent;
    }
    return <PhonePeInvoiceContext.Provider value={{ invoice: invoice }}>{children}</PhonePeInvoiceContext.Provider>
};

export default PhonePeInvoiceLoader;