import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import EnvContext from "../Env";
import StyledFlatButton from "./buttons/StyledFlatButton";

export const ConctactUsButton = () => {
    const env = useContext(EnvContext);
    const handleClick = () => {
        window.open(env.supportLink, '_blank', 'noopener,noreferrer');
    };
    return <StyledFlatButton handleClick={handleClick}><FormattedMessage id="btn.contact_us" /></StyledFlatButton>
}