import './StyledPage.css';
import Footer from './footer/Footer';
import { Box } from '@mui/material';

const AppPage = ({
    children
}: {
    children: JSX.Element | JSX.Element[] | string | null
}) => {
    return (
        <div className='AppPage'>
            <Box sx={{ height: { mobile: '8px', tablet: '24px' } }} />
            <div className='AppPageContent'>
                {children}
            </div>
            <Box sx={{ height: { mobile: '8px', tablet: '24px' } }} />
            <Footer />
            <Box sx={{ height: { mobile: '8px', tablet: '24px' } }} />
        </div>
    );
};

export default AppPage;