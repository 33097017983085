import { Typography } from "@mui/material";

const BinanceStepDescription = ({ children }: { children: any }) => {
    return <Typography variant="body1" sx={{
        textAlign: 'start',
        marginLeft: '24px',
        marginRight: '24px',
    }}>{children}</Typography>
};

export default BinanceStepDescription;